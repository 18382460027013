import ListGroup from "react-bootstrap/ListGroup";
import { Container, Row, Col } from "react-bootstrap";
import Steps from "./index.js";
import Accordion from "./Accordion";
import FAQs from "./FAQs.js";

const FAQsContainer = () => {
  const accordionData = [
    {
      title: "About the New Blockchain",
      content: <FAQs />,
    },
    {
      title: "Test out the Explorer: How to Perform a Transaction",
      content: <Steps />,
    },
  ];

  return (
    <div>
      <Container fluid>
        <Row className="justify-content-md-center">
          <Col></Col>
          <Col md="auto">
            <ListGroup as="ul" horizontal className="accordion">
              {accordionData.map(({ title, content }) => (
                <ListGroup.Item as="li" className="accordion-item-custom">
                  <Accordion
                    title={title}
                    content={content}
                    className={"blah"}
                  />
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </div>
  );
};

export default FAQsContainer;
